import React from "react";
import "../../Clients/ClientsStyles.css";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Form,
  Table,
  Modal,
  Spin,
} from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { bindActionCreators } from "redux";
import {
  getClientTag,
  getParentInvoiceListing,
  deleteInvoice,
  getInvoiceInfo,
  setInvoicesDataNull,
  getCompany,
  getChildInvoiceListing,
  getInvoicePDFHtmlBlob,
  getInvoiceStatuses,
  setEmailTemplate,
  addEmailDocument,
} from "../../store/Actions/index";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import SendEmailFromInvoiceListing from "../../Clients/SendEmailFromInvoiceListing";
const { Option } = Select;
const dateFormat = "DD/MM/YYYY";
class Invoices extends React.Component {
  constructor(props) {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let userEmail = localStorage.getItem("userEmail");
    let userId = localStorage.getItem("userId");
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    let profileData = JSON.parse(sessionStorage.getItem("profileData"));

    super(props);
    this.state = {
      showForm: "listing",
      invoicesList: [],
      visibleDeleteModal: false,
      dateFrom: "1900-01-01T00:00:00+00",
      dateTo: "1900-01-01T00:00:00+00",
      clientProfileId:
        props && props.potentialClientInfo && props.potentialClientInfo.id,
      clientTagId: 0,
      loading: false,
      parentInvoiceId: 0,
      childInvoices: [],
      childrenTotalAmount: 0,
      parentTotalAmount: 0,
      expandedRowKeys: "",
      pageNumber: 1,
      pageSize: 5,
      totalPages: 0,
      defaultCurrent: 1,
      userEmail: userEmail,
      userId: userId,
      branchId: selectedBranchId,
      invoiceType: "pclient",
      visibleSendingReceiptModal: false,
    };
  }

  handleCancelSendingReceiptModal = (e) => {
    this.setState(
      {
        visibleSendingReceiptModal: false,
      },
      () => {}
    );
  };

  setExpandedRowKeys = () => {
    this.setState({ expandedRowKeys: [] });
  };

  setLoadingTrue = () => {
    this.setState({ loading: true });
  };

  setLoadingFalse = () => {
    this.setState({ loading: false });
  };

  columns = [
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div
          className="invoice-status-btn"
          style={{
            backgroundColor:
              record.statusName === "Saved"
                ? "#f0ad4e"
                : record.statusName === "Sent"
                ? "#5bc0de"
                : record.statusName === "Completed" ||
                  record.statusName === "Completed/Sent"
                ? "#5cb85c"
                : record.statusName === "Posted"
                ? "#9932CC"
                : record.statusName === "Reconciled"
                ? "#2E8B57"
                : record.statusName === "Paid"
                ? "#B87333"
                : record.statusName === "Settled"
                ? "#f64a8a"
                : "#ffff",
            width: "70px",
          }}
        >
          <span className="invoice-status-text">{record.statusName}</span>
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: "invoiceTypeName",
      key: "invoiceTypeName",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record.invoiceTypeName}
        </p>
      ),
    },
    {
      title: "Date",
      dataIndex: "dateStr",
      key: "dateStr",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {moment(record.dateStr).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: "Invoice#",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record.invoiceNo}
        </p>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {moment(record.dueDate).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: "Payment",
      dataIndex: "payment",
      key: "payment",
      render: (text, record) => {
        // let _items =
        //   record.payments && record.payments.items && record.payments.items;
        // let _amount = 0;
        // if (_items && _items.length > 0) {
        //   for (let index = 0; index < _items.length; index++) {
        //     _amount = _amount + _items[index].amount;
        //   }
        // }

        return (
          <p
            style={{
              fontSize: "12px",
              fontWeight: "400",
              fontStyle: "normal",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {record.paidAmount}
          </p>
        );
      },
    },
    {
      title: "Due/Over Due Amount",
      dataIndex: "dueAmount",
      key: "dueAmount",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record.dueAmount}
        </p>
      ),
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <a
          onClick={() => {
            this.setState({ loading: true });
            this.props
              .getInvoiceInfo(record.id)
              .then(() => {
                this.setState({ loading: false });
                this.handleClickViewDetail();
              })
              .catch(() => {
                this.setState({ loading: false });
              });
          }}
        >
          {"View Details"}
        </a>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <span style={{ display: "flex" }}>
          {/* <a
            href="javascript:"
            onClick={(e) => {
              e.stopPropagation();
              this.showModalDelete(record.id);
            }}
          >
            <DeleteOutlined />
          </a> */}
          <Button
            type="primary"
            size="small"
            style={{ marginLeft: "10px" }}
            onClick={() => this.onClickEmail(record)}
            className="button-blue"
          >
            {"Email"}
          </Button>
        </span>
      ),
    },
  ];

  showModalDelete = (id) => {
    this.setState({
      visibleDeleteModal: true,
      index: id,
    });
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.props.getClientTag();
    let _branchData = this.props.branchData;
    let _branchName;
    if (_branchData && _branchData.length > 0) {
      for (
        let branchIndex = 0;
        branchIndex < _branchData.length;
        branchIndex++
      ) {
        if (_branchData[branchIndex].id === this.state.branchId) {
          _branchName = _branchData[branchIndex].name;
        }
        this.setState({ branchName: _branchName });
      }
    }

    this.props
      .getParentInvoiceListing(this.state)
      .then(() => {
        let _invoiceListingCount =
          this.props &&
          this.props.invoiceListingCount &&
          this.props.invoiceListingCount;
        this.setState({ loading: false, totalPages: _invoiceListingCount });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let _invoiceListingCount =
      nextProps &&
      nextProps.invoiceListingCount &&
      nextProps.invoiceListingCount;

    let _invoicesList =
      nextProps && nextProps.invoiceListing && nextProps.invoiceListing;
    this.setState({
      invoicesList: _invoicesList,
      totalPages: _invoiceListingCount,
    });
  }
  paginate = (value) => {
    this.setState(
      { pageSize: value.pageSize, pageNumber: value.current, loading: true },
      () => {
        this.props
          .getParentInvoiceListing(this.state)
          .then(() => {
            let _length;
            _length =
              this.props &&
              this.props.invoiceListingCount &&
              this.props.invoiceListingCount;
            this.setState({
              loading: false,
              defaultCurrent: value.current,
              totalPages: _length,
              expandedRowKeys: [],
            });
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      }
    );
  };

  addInvoice = () => {
    this.props.setInvoicesDataNull();
    this.setState({ parentInvoiceId: 0 }, () => {
      this.props.getChildInfo(
        this.state.parentInvoiceId,
        this.state.childrenTotalAmount,
        this.state.parentTotalAmount,
        "add"
      );
    });
  };

  addInvoiceChild = () => {
    this.setState({ parentInvoiceId: this.state.idInvoice }, () => {
      this.props.getChildInfo(
        this.state.parentInvoiceId,
        this.state.childrenTotalAmount,
        this.state.parentTotalAmount,
        "add"
      );
      console.log("parentinvoiceid", this.state.idInvoice);
    });
  };

  handleClickViewDetail = () => {
    this.setState({ parentInvoiceId: 0 }, () => {
      this.props.getChildInfo(
        this.state.parentInvoiceId,
        this.state.childrenTotalAmount,
        this.state.parentTotalAmount,
        "add"
      );
      console.log("parentinvoiceid", this.state.idInvoice);
    });
  };

  handleCancelDeleteModal = (e) => {
    this.setState({
      visibleDeleteModal: false,
    });
  };

  getModalDelete = () => {
    return (
      <div>
        <Modal
          title="Delete"
          visible={this.state.visibleDeleteModal}
          onCancel={this.handleCancelDeleteModal}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure, you want to delete invoice?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={5} offset={15}>
              <Button
                onClick={() => {
                  this.handleCancelDeleteModal();
                }}
                style={{ borderRadius: "5px" }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className="button-blue"
                className={"button"}
                onClick={() => {
                  this.handleDeleteInvoice(this.state.index);
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  handleDeleteInvoice = (id) => {
    this.props.deleteInvoice(id).then(() => {
      message.success("Invoice is deleted successfully!");
      this.setState({ visibleDeleteModal: false });
      setTimeout(() => {
        this.setState({ expandedRowKeys: [], loading: true });
        this.props
          .getParentInvoiceListing(this.state)
          .then(() => {
            this.setState({ loading: false });
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      }, 2000);
    });
  };

  handleChangeDateFrom = (date, dateString) => {
    this.setState(
      {
        dateFrom:
          date === null
            ? "1900-01-01T00:00:00+00"
            : moment(date).format("YYYY-MM-DDT00:00:00+00:00"),
      },
      () => {}
    );
  };

  handleChangeDateTo = (date, dateString) => {
    this.setState(
      {
        dateTo:
          date === null
            ? "1900-01-01T00:00:00+00"
            : moment(date).format("YYYY-MM-DDT00:00:00+00:00"),
      },
      () => {}
    );
  };

  handleChangeClientTags = (value) => {
    this.setState({ clientTagId: value }, () => {});
  };

  show = () => {
    // this.setState({ loading: true });
    this.setState({ pageNumber: 1, pageSize: 5 }, () => {
      this.props
        .getParentInvoiceListing(this.state)
        .then(() => {
          let _length;
          _length =
            this.props &&
            this.props.invoiceListingCount &&
            this.props.invoiceListingCount;
          this.setState({ defaultCurrent: 1, totalPages: _length });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    });
  };

  expandedColumns = [
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div
          className="invoice-status-btn"
          style={{
            backgroundColor:
              record.statusName === "Saved"
                ? "#f0ad4e"
                : record.statusName === "Sent"
                ? "#5bc0de"
                : record.statusName === "Completed" ||
                  record.statusName === "Completed/Sent"
                ? "#5cb85c"
                : record.statusName === "Posted"
                ? "#9932CC"
                : record.statusName === "Reconciled"
                ? "#2E8B57"
                : record.statusName === "Paid"
                ? "#B87333"
                : record.statusName === "Settled"
                ? "#f64a8a"
                : "#ffff",
            width: "70px",
          }}
        >
          <span className="invoice-status-text">{record.statusName}</span>
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "dateStr",
      key: "dateStr",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {moment(record.dateStr).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: "Invoice#",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record.invoiceNo}
        </p>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {moment(record.dueDate).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: "Payment",
      dataIndex: "payment",
      key: "payment",
      render: (text, record) => {
        let _items =
          record.payments && record.payments.items && record.payments.items;
        let _amount = 0;
        if (_items && _items.length > 0) {
          for (let index = 0; index < _items.length; index++) {
            _amount = _amount + _items[index].amount;
          }
        }

        return (
          <p
            style={{
              fontSize: "12px",
              fontWeight: "400",
              fontStyle: "normal",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {record.invoiceTypeName === "ProForma Invoice"
              ? _amount + record.paidAmount
              : _amount}
          </p>
        );
      },
    },
    {
      title: "Due/Over Due Amount",
      dataIndex: "dueAmount",
      key: "dueAmount",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record.dueAmount}
        </p>
      ),
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <a
          onClick={() => {
            this.setState({ loading: true });
            let _childrenTotalAmount = 0;
            _childrenTotalAmount =
              this.state.childrenTotalAmount - record.total;

            this.setState({ childrenTotalAmount: _childrenTotalAmount }, () => {
              this.props
                .getInvoiceInfo(record.id)
                .then(() => {
                  this.setState({ loading: false });
                  this.addInvoiceChild();
                })
                .catch(() => {
                  this.setState({ loading: false });
                });
            });
          }}
        >
          {"View Details"}
        </a>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      // width: "105px",
      render: (text, record) => (
        <span style={{ display: "flex" }}>
          {/* <a
            href="javascript:"
            onClick={(e) => {
              e.stopPropagation();
              this.showModalDelete(record.id);
            }}
          >
            <DeleteOutlined color="rgb(51, 170, 218)" />
          </a> */}
          <Button
            type="primary"
            size="small"
            style={{ marginLeft: "10px" }}
            onClick={() => this.onClickEmail(record)}
            className="button-blue"
          >
            {"Email"}
          </Button>
        </span>
      ),
    },
  ];

  onExpand = (record, expanded) => {
    this.setState({ expandedRowKeys: record.key });
    if (expanded) {
      this.setState({ expandedRowKeys: [] });
    }
    let _childrenTotalAmount = 0;
    this.setState({ idInvoice: record.id, parentTotalAmount: record.total });
    let _data = record && record.links && record.links[1].value;
    this.props.getChildInvoiceListing(_data).then((res) => {
      let _childInvoices =
        res && res.payload && res.payload.items && res.payload.items;
      if (_childInvoices && _childInvoices) {
        for (let index = 0; index < _childInvoices.length; index++) {
          _childrenTotalAmount =
            _childrenTotalAmount + _childInvoices[index].total;
        }
      }

      this.setState({
        childInvoices: _childInvoices,
        childrenTotalAmount: _childrenTotalAmount,
      });
    });
  };

  onClickEmail = (record) => {
    this.setState({ loading: true }, () => {
      this.props
        .getInvoiceInfo(record.id)
        .then((_res) => {
          let _invoiceInfo = _res && _res.payload;

          this.setState({ invoiceInfo: _invoiceInfo }, () => {
            this.props
              .getInvoiceStatuses(this.state.invoiceInfo.invoiceTypeId)
              .then((res) => {
                let _statuses =
                  res && res.payload && res.payload.items && res.payload.items;
                this.setState({ statuses: _statuses }, () => {
                  let _tempInvoiceInfo = this.state.invoiceInfo;
                  let _statusId;
                  if (
                    _tempInvoiceInfo &&
                    _tempInvoiceInfo.invoiceTypeId === 2 &&
                    _tempInvoiceInfo.statusId === 27
                  ) {
                    _statusId = _tempInvoiceInfo.statusId;
                  } else {
                    if (
                      _tempInvoiceInfo.dueAmount !== 0 &&
                      _tempInvoiceInfo.dueAmount > 0
                    ) {
                      if (_statuses && _statuses.length > 0) {
                        _statusId = _statuses[0].id;
                      }
                    } else {
                      _statusId = _statuses[4].id;
                    }
                  }
                  _tempInvoiceInfo.invoiceId = _tempInvoiceInfo.id;
                  _tempInvoiceInfo.clientProfileId = this.state.clientProfileId;
                  _tempInvoiceInfo.statusId = _statusId;
                  _tempInvoiceInfo.dateStr = moment(
                    _tempInvoiceInfo.dateStr
                  ).format("DD/MM/YYYY");
                  _tempInvoiceInfo.dueDate = moment(
                    _tempInvoiceInfo.dueDate
                  ).format("DD/MM/YYYY");

                  this.setState(
                    {
                      invoiceInfo: _tempInvoiceInfo,
                    },
                    () => {
                      let _emailTemplatePayload = {
                        templateName: "invoice",
                        parameters: [
                          {
                            key: "ClientId",
                            value: this.state.clientProfileId,
                          },
                          {
                            key: "CurrencyId",
                            value: this.state.invoiceInfo.currencyId
                              ? this.state.invoiceInfo.currencyId.toString()
                              : "106",
                          },
                          {
                            key: "UserId",
                            value: this.state.userId,
                          },
                          {
                            key: "Content",
                            value: "Invoice",
                          },
                          {
                            key: "Amount",
                            value: this.state.invoiceInfo.total.toString(),
                          },
                          {
                            key: "InvoiceNumber",
                            value: this.state.invoiceInfo.invoiceNo,
                          },
                          {
                            key: "DueDate",
                            value: this.state.invoiceInfo.dueDate,
                          },
                        ],
                      };
                      this.props
                        .setEmailTemplate(_emailTemplatePayload)
                        .then(() => {
                          let _setEmailData = this.props.setEmailData;

                          this.setState({ setEmailData: _setEmailData }, () => {
                            this.setState({}, () => {
                              this.setState(
                                {
                                  currentPaymentId: 0,
                                },
                                () => {
                                  let _data = {
                                    invoiceId:
                                      this.state &&
                                      this.state.invoiceInfo &&
                                      this.state.invoiceInfo.id,
                                    paymentId: this.state.currentPaymentId,
                                  };
                                  this.props
                                    .getInvoicePDFHtmlBlob(_data)
                                    .then((res) => {
                                      let formData = new FormData();

                                      let binaryString = window.atob(
                                        res.payload.url
                                      );
                                      let binaryLen = binaryString.length;
                                      let bytes = new Uint8Array(binaryLen);
                                      for (let i = 0; i < binaryLen; i++) {
                                        let ascii = binaryString.charCodeAt(i);
                                        bytes[i] = ascii;
                                      }
                                      let _blob = new Blob([bytes], {
                                        type: "application/pdf",
                                      });
                                      let metadata = {
                                        type: "application/pdf",
                                      };
                                      let file = new File(
                                        [_blob],
                                        res.payload.name,
                                        metadata
                                      );

                                      formData.append(
                                        "BranchId",
                                        localStorage.getItem("selectedBranchId")
                                      );

                                      formData.append("files", file);
                                      this.props
                                        .onAddEmailDocument(formData)
                                        .then((res) => {
                                          let _attData = res && res.payload;
                                          let attDataArray = [
                                            {
                                              name: _attData[0].fileName,
                                              type: _attData[0].contentType,
                                              url: _attData[0].fileUrl,
                                            },
                                          ];

                                          this.setState({
                                            attDataArray: attDataArray,
                                            loading: false,
                                            visibleSendingReceiptModal: true,
                                          });
                                        })
                                        .catch(() => {
                                          this.setState({ loading: false });
                                        });
                                    })
                                    .catch(() => {
                                      this.setState({ loading: false });
                                    });
                                }
                              );
                            });
                          });
                        })
                        .catch(() => {
                          this.setState({ loading: false });
                        });
                    }
                  );
                });
              })
              .catch(() => {
                this.setState({ loading: false });
              });
          });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    });
  };

  addChildInvoice = () => {
    this.props.setInvoicesDataNull();
    this.setState({ parentInvoiceId: this.state.idInvoice }, () => {
      this.props.getChildInfo(
        this.state.parentInvoiceId,
        this.state.childrenTotalAmount,
        this.state.parentTotalAmount,
        "add"
      );
    });
  };

  getListingView = () => {
    return (
      <div
        className="border-box emp-profile-box"
        style={{ backgroundColor: "white " }}
      >
        <h4 style={{ marginBottom: "8px", marginLeft: "12px" }}>
          {this.props.potentialClientInfo &&
          this.props.potentialClientInfo.firstName
            ? `Name: ${this.props.potentialClientInfo.firstName.toUpperCase()} ${this.props.potentialClientInfo.lastName.toUpperCase()}`
            : `Add a New Potential Client`}
        </h4>
        <div className="ca-gray-cont">
          <Row>
            <Col xs={11}>
              <Row>
                <Col xs={11} offset={1}>
                  <DatePicker
                    format={dateFormat}
                    placeholder={"SELECT DATE FROM"}
                    onChange={this.handleChangeDateFrom}
                  />
                </Col>
                <Col xs={11} offset={1}>
                  <DatePicker
                    placeholder={"SELECT DATE TO"}
                    format={dateFormat}
                    onChange={this.handleChangeDateTo}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={13}>
              <Row>
                <Col xs={12} offset={1}>
                  <div style={{ width: "100%", display: "flex" }}>
                    <p
                      className={"font-school-list-filter-text"}
                      style={{ width: "40%" }}
                    >
                      CLIENT TAGS
                    </p>
                    <div style={{ width: "50%" }}>
                      <Select
                        className={"width-web-kit"}
                        placeholder={"SELECT CLIENT TAGS"}
                        onChange={this.handleChangeClientTags}
                      >
                        <Option value={0}>{"Select"}</Option>
                        {this.props &&
                          this.props.clientTagRes &&
                          this.props.clientTagRes.items &&
                          this.props.clientTagRes.items.map((data) => {
                            // eslint-disable-next-line react/jsx-no-undef
                            return <Option value={data.id}>{data.name}</Option>;
                          })}
                      </Select>
                    </div>
                  </div>
                </Col>
                <Col xs={5} offset={1}>
                  <Button
                    type="primary"
                    className="button-blue"
                    onClick={this.show}
                  >
                    SHOW
                  </Button>
                </Col>
                <Col xs={5}>
                  <Button
                    className="button-blue"
                    type="primary"
                    onClick={this.addInvoice}
                  >
                    ADD
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div
          className="ca-gray-cont"
          style={{ padding: 20, paddingBottom: 60 }}
        >
          <Row>
            <Col xs={24} className={"school-table overflow"}>
              <Table
                expandedRowKeys={this.state.expandedRowKeys}
                expandable={{
                  expandIconColumnIndex: 9,
                  // expandedRowKeys: [this.state.invoicesList[0].id],
                  expandedRowRender: (record) => (
                    <Row>
                      <Col xs={24}>
                        <Row>
                          <Col xs={4} offset={20}>
                            <div style={{ marginLeft: "20px" }}>
                              <Button
                                className="button-blue"
                                type="primary"
                                onClick={this.addChildInvoice}
                              >
                                ADD CHILD
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            xs={24}
                            className="accounts-flow-table school-table"
                          >
                            <Table
                              dataSource={
                                this.state.childInvoices
                                  ? this.state.childInvoices
                                  : null
                              }
                              rowClassName={(record, index) =>
                                "invoices-children-table-row"
                              }
                              onChange={this.paginate}
                              columns={this.expandedColumns}
                              bordered={false}
                              pagination={false}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ),

                  expandIcon: ({ expanded, onExpand, record }) => (
                    <div className="table-action" type="right">
                      {record.invoiceTypeId === 2 ? (
                        <PlusCircleOutlined
                          style={{ float: "right" }}
                          onClick={(e) => {
                            onExpand(record, e);
                            this.onExpand(record, expanded);
                          }}
                        />
                      ) : null}
                    </div>
                  ),
                }}
                dataSource={
                  this.state.invoicesList ? this.state.invoicesList : null
                }
                columns={this.columns}
                pagination={{
                  defaultCurrent: this.state.defaultCurrent,
                  total: this.state.totalPages,
                  defaultPageSize: 20,
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  render() {
    const letterTempaltesFilter = (employerManagRes) => {
      let filterList = [];
      if (
        this.state &&
        this.state.invoicesList &&
        this.state.invoicesList.length > 0
      ) {
        this.state &&
          this.state.invoicesList &&
          this.state.invoicesList.map((data, index) => {
            if (data) {
              data.index = index;
              data.key = `${index + 1}`;
              filterList.push(data);
            }
          });
      }
    };
    return (
      <div>
        {this.state.loading ? (
          <div className={"spinner"}>
            <Spin size="large" />
          </div>
        ) : (
          <div>
            {this.state.showForm === "listing" ? this.getListingView() : null}
            {this.getModalDelete()}
            {letterTempaltesFilter()}
            {this.state.visibleSendingReceiptModal ? (
              <SendEmailFromInvoiceListing
                clientId={this.props.potentialClientInfo.id}
                setLoadingTrue={this.setLoadingTrue}
                setLoadingFalse={this.setLoadingFalse}
                state={this.state}
                invoiceType={this.state.invoiceType}
                setExpandedRowKeys={this.setExpandedRowKeys}
                statuses={this.state.statuses}
                attDataArray={this.state.attDataArray}
                setEmailData={this.state.setEmailData}
                clientEmail={
                  this.props &&
                  this.props.potentialClientInfo &&
                  this.props.potentialClientInfo.email
                }
                userEmail={this.state.userEmail}
                paymentId={this.state.currentPaymentId}
                invoiceNo={
                  this.state.invoiceInfo && this.state.invoiceInfo.invoiceNo
                }
                invoiceId={this.state.invoiceInfo && this.state.invoiceInfo.id}
                branchName={this.state.branchName}
                handleCancelSendingReceiptModal={
                  this.handleCancelSendingReceiptModal
                }
                invoiceInfo={this.state.invoiceInfo}
                visibleSendingReceiptModal={
                  this.state.visibleSendingReceiptModal
                }
                fromPotentialClient={true}
                isPotential={true}
              />
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientTagRes:
      state &&
      state.accountSetReducer &&
      state.accountSetReducer.clientTagRes &&
      state.accountSetReducer.clientTagRes,
    invoiceListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoiceListing &&
      state.invoicesReducer.invoiceListing &&
      state.invoicesReducer.invoiceListing.items &&
      state.invoicesReducer.invoiceListing.items,
    invoiceListingCount:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoicesCount &&
      state.invoicesReducer.invoicesCount,
    setEmailData:
      state &&
      state.questionnaireReducer &&
      state.questionnaireReducer.setEmailData &&
      state.questionnaireReducer.setEmailData,
    clientProfileData:
      state.clientProfileReducer &&
      state.clientProfileReducer.clientProfileData,
    branchData:
      state &&
      state.branchReducer &&
      state.branchReducer.branchData &&
      state.branchReducer.branchData.items &&
      state.branchReducer.branchData.items,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getClientTag: bindActionCreators(getClientTag, dispatch),
  getParentInvoiceListing: bindActionCreators(
    getParentInvoiceListing,
    dispatch
  ),
  deleteInvoice: bindActionCreators(deleteInvoice, dispatch),
  getInvoiceInfo: bindActionCreators(getInvoiceInfo, dispatch),
  setInvoicesDataNull: bindActionCreators(setInvoicesDataNull, dispatch),
  getCompany: bindActionCreators(getCompany, dispatch),
  getChildInvoiceListing: bindActionCreators(getChildInvoiceListing, dispatch),
  getInvoicePDFHtmlBlob: bindActionCreators(getInvoicePDFHtmlBlob, dispatch),
  getInvoiceStatuses: bindActionCreators(getInvoiceStatuses, dispatch),
  setEmailTemplate: bindActionCreators(setEmailTemplate, dispatch),
  onAddEmailDocument: bindActionCreators(addEmailDocument, dispatch),
});
Invoices = connect(mapStateToProps, mapDispatchToProps)(Invoices);

export default withRouter(Invoices);
