import React, { Fragment } from "react";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Input,
  Form,
  Table,
  Modal,
  Spin,
  Tabs,
} from "antd";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FileTextFilled,
  FileTextOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { bindActionCreators } from "redux";
import {
  deleteInvoice,
  getAllTypesInvoices,
  getChildInvoiceListing,
  getClientTag,
  getCompany,
  getDueOverDueInvoices,
  getInvoiceInfo,
  getParentInvoiceListing,
  getPotentialClientInfo,
  getPotentialClientUpdateBol,
  getProfileClientTag,
  getSchoolInvoiceInfo,
  getTeamMember,
  getTemplateInvoiceInfo,
} from "../../store/Actions";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { apiRefresh } from "../../services/api";
import { saveAs } from "file-saver";
const { Option } = Select;
const dateFormat = "DD/MM/YYYY";
const { TabPane } = Tabs;

class DueInvoiceListing extends React.Component {
  constructor(props) {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    super(props);
    this.state = {
      branchId: selectedBranchId,
      subjectTypeId: 0,
      pageNumber: 1,
      pageSize: 20,
      dateFrom: "1900-01-01T00:00:00+00:00",
      dateTo: "1900-01-01T00:00:00+00:00",
      dueOverInvoices: [],
      allTypesInvoices: [],
      loading: false,
      totalPages: 0,
      defaultCurrent: 1,
      loadingTable: false,
      name: "",
      email: "",
      invoiceNo: "",
      clientTagId: 0,
      parentInvoiceId: 0,
      childInvoices: [],
      childrenTotalAmount: 0,
      parentTotalAmount: 0,
      expandedRowKeys: "",
      processingPerson: "",
      teamMembersState: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    if (this.props && this.props.keyd === "6") {
      this.props.getTeamMember();
      this.props
        .getAllTypesInvoices(this.state)
        .then(() => {
          this.props.getClientTag();
          this.setState({ loading: false });
        })
        .catch(() => {
          this.props.getClientTag();
          this.setState({ loading: false });
        });
    } else {
      this.props
        .getDueOverDueInvoices(this.state)
        .then(() => {
          this.props.getClientTag();
          this.setState({ loading: false });
        })
        .catch(() => {
          this.props.getClientTag();
          this.setState({ loading: false });
        });
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let _selectedBranchId = nextProps && nextProps.selectedBranch;
    if (this.state.branchId !== _selectedBranchId) {
      this.setState({ branchId: _selectedBranchId }, () => {
        // this.props.getClientTag().then(() => {});
      });
    }
    let _dueOverInvoices =
      nextProps && nextProps.dueOverInvoices && nextProps.dueOverInvoices;
    let _dueOverInvoicesCount =
      nextProps &&
      nextProps.dueOverInvoicesCount &&
      nextProps.dueOverInvoicesCount;
    let _allTypesInvoices =
      nextProps && nextProps.allTypesInvoices && nextProps.allTypesInvoices;
    let _allTypesInvoicesCount =
      nextProps &&
      nextProps.allTypesInvoicesCount &&
      nextProps.allTypesInvoicesCount;
    this.setState({
      dueOverInvoices: _dueOverInvoices,
      allTypesInvoices: _allTypesInvoices,
      totalPages:
        this.props && this.props.keyd === "6"
          ? _allTypesInvoicesCount
          : _dueOverInvoicesCount,
    });
    if (nextProps.teamMembers) {
      let users = [];
      if (
        localStorage.getItem("userOwner") === "True" ||
        localStorage.getItem("userManager") === "true"
      ) {
        nextProps.teamMembers.map((user, ind) => {
          users.push(<Option value={user.id}>{user.fullName}</Option>);
        });
      } else {
        let findUser = nextProps.teamMembers.find(
          (obj) => obj.id == localStorage.getItem("userId")
        );
        if (findUser) {
          users.push(<Option value={findUser.id}>{findUser.fullName}</Option>);
        }
      }
      this.setState({ teamMembersState: users });
    }
  }

  handleChangeDateFrom = (date, dateString) => {
    this.setState(
      {
        dateFrom:
          date === null
            ? "1900-01-01T00:00:00+00"
            : moment(date).format("YYYY-MM-DDT00:00:00+00:00"),
      },
      () => {}
    );
  };

  handleChangeDateTo = (date, dateString) => {
    this.setState(
      {
        dateTo:
          date === null
            ? "1900-01-01T00:00:00+00"
            : moment(date).format("YYYY-MM-DDT00:00:00+00:00"),
      },
      () => {}
    );
  };

  handleChangeClientTags = (value) => {
    this.setState({ clientTagId: value });
  };

  show = () => {
    this.setState({ pageNumber: 1, pageSize: 20, loadingTable: true }, () => {
      this.props && this.props.keyd === "6"
        ? this.props
            .getAllTypesInvoices(this.state)
            .then(() => {
              let _length;
              _length =
                this.props &&
                this.props.allTypesInvoicesCount &&
                this.props.allTypesInvoicesCount;
              this.setState({
                defaultCurrent: 1,
                loadingTable: false,
                totalPages: _length,
              });
            })
            .catch(() => {
              this.setState({ loadingTable: false });
            })
        : this.props
            .getDueOverDueInvoices(this.state)
            .then(() => {
              let _length;
              _length =
                this.props &&
                this.props.dueOverInvoicesCount &&
                this.props.dueOverInvoicesCount;
              this.setState({
                defaultCurrent: 1,
                loadingTable: false,
                totalPages: _length,
              });
            })
            .catch(() => {
              this.setState({ loadingTable: false });
            });
    });
  };

  paginate = (value) => {
    this.setState(
      {
        pageSize: value.pageSize,
        pageNumber: value.current,
        loadingTable: true,
      },
      () => {
        this.props && this.props.keyd === "6"
          ? this.props
              .getAllTypesInvoices(this.state)
              .then(() => {
                let _length;
                _length =
                  this.props &&
                  this.props.allTypesInvoicesCount &&
                  this.props.allTypesInvoicesCount;
                this.setState({
                  loadingTable: false,
                  defaultCurrent: value.current,
                  totalPages: _length,
                });
              })
              .catch(() => {
                this.setState({ loadingTable: false });
              })
          : this.props
              .getDueOverDueInvoices(this.state)
              .then(() => {
                let _length;
                _length =
                  this.props &&
                  this.props.dueOverInvoicesCount &&
                  this.props.dueOverInvoicesCount;
                this.setState({
                  loadingTable: false,
                  defaultCurrent: value.current,
                  totalPages: _length,
                });
              })
              .catch(() => {
                this.setState({ loadingTable: false });
              });
      }
    );
  };

  showAll = () => {
    this.setState({ loading: true });
    this.setState(
      {
        subjectTypeId: 2,
        pageNumber: 1,
        pageSize: 20,
        dateFrom: "1900-01-01T00:00:00+00:00",
        dateTo: "1900-01-01T00:00:00+00:00",
        name: "",
        email: "",
        clientTagId: 0,
        invoiceNo: "",
      },
      () => {
        this.props && this.props.keyd === "6"
          ? this.props
              .getAllTypesInvoices(this.state)
              .then(() => {
                let _length;
                _length =
                  this.props &&
                  this.props.allTypesInvoicesCount &&
                  this.props.allTypesInvoicesCount;
                this.setState({
                  loading: false,
                  defaultCurrent: 1,
                  totalPages: _length,
                });
              })
              .catch(() => {
                this.setState({ loading: false });
              })
          : this.props
              .getDueOverDueInvoices(this.state)
              .then(() => {
                let _length;
                _length =
                  this.props &&
                  this.props.dueOverInvoicesCount &&
                  this.props.dueOverInvoicesCount;
                this.setState({
                  loading: false,
                  defaultCurrent: 1,
                  totalPages: _length,
                });
              })
              .catch(() => {
                this.setState({ loading: false });
              });
      }
    );
  };
  handleChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  handleChangeName = (e) => {
    this.setState({ name: e.target.value });
  };

  handleChangeInvoiceNumber = (e) => {
    this.setState({ invoiceNo: e.target.value });
  };

  exportInvoice = () => {
    let data = {
      subjectTypeId: this.state.subjectTypeId,
      pageNumber: this.state.pageNumber,
      pageSize: this.state.pageSize,
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      name: this.state.name,
      email: this.state.email,
      clientTagId: this.state.clientTagId,
      invoiceNo: this.state.invoiceNo,
      processingPerson:
        this.state.processingPerson ||
        (localStorage.getItem("userSystemPermissions") &&
          JSON.parse(
            decodeURIComponent(
              escape(window.atob(localStorage.getItem("userSystemPermissions")))
            )
          ).find((x) => x.role.toLowerCase() == "accounts").status == 1 &&
          JSON.parse(
            decodeURIComponent(
              escape(window.atob(localStorage.getItem("userSystemPermissions")))
            )
          ).find((x) => x.role.toLowerCase() == "potential clients").status ==
            0 &&
          JSON.parse(
            decodeURIComponent(
              escape(window.atob(localStorage.getItem("userSystemPermissions")))
            )
          ).find((x) => x.role.toLowerCase() == "client database").status ==
            0 &&
          localStorage.getItem("userId")) ||
        "00000000-0000-0000-0000-000000000000",
    };
    const clientList = {
      url: `v1/invoice/AllInvoicesExport`,
    };

    clientList.types = [
      "EXPORT_SEARCH_CLIENT_SUCCESS",
      "EXPORT_SEARCH_CLIENT_FAILURE",
    ];
    this.setState({ loading: true });
    apiRefresh
      .postWithFileRes(clientList, data)
      .then((res) => {
        this.setState({ loading: false });
        var blob = new Blob([res], {
          type: "application/octet-stream",
        });
        saveAs(blob, "AllInvoices.xlsx");
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handleChangeProcessingPerson = (value) => {
    this.setState({ processingPerson: value });
  };

  getFiltersSection = () => {
    return (
      <div>
        <Row style={{ marginBottom: "19px" }}>
          <Col xs={11} offset={1}>
            <div style={{ width: "100%", display: "flex" }}>
              <div className="xero-filters-label" style={{ width: "24%" }}>
                <p className={"font-school-list-filter-text-sub"}>Name</p>
              </div>
              <div style={{ width: "75%" }}>
                <Input size="medium" onChange={this.handleChangeName} />
              </div>
            </div>
          </Col>
          <Col xs={11} offset={1}>
            <div style={{ width: "100%", display: "flex" }}>
              <div className="xero-filters-label" style={{ width: "24%" }}>
                <p className={"font-school-list-filter-text-sub"}>Email</p>
              </div>
              <div style={{ width: "80%" }}>
                <Input size="medium" onChange={this.handleChangeEmail} />
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "19px" }}>
          <Col xs={11} offset={1}>
            <div style={{ width: "100%", display: "flex" }}>
              <div className="xero-filters-label" style={{ width: "24%" }}>
                <p className={"font-school-list-filter-text-sub"}>Date From</p>
              </div>
              <div style={{ width: "75%" }}>
                <DatePicker
                  placeholder="Date From"
                  size="small"
                  format={dateFormat}
                  onChange={this.handleChangeDateFrom}
                />
              </div>
            </div>
          </Col>
          <Col xs={11} offset={1}>
            <div style={{ width: "100%", display: "flex" }}>
              <div className="xero-filters-label" style={{ width: "24%" }}>
                <p className={"font-school-list-filter-text-sub"}>Date To</p>
              </div>
              <div style={{ width: "75%" }}>
                <DatePicker
                  placeholder="Date To"
                  size="small"
                  format={dateFormat}
                  onChange={this.handleChangeDateTo}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={11} offset={1}>
            <div style={{ width: "100%", display: "flex" }}>
              <div className="xero-filters-label" style={{ width: "24%" }}>
                <p className={"font-school-list-filter-text-sub"}>Invoice #</p>
              </div>
              <div style={{ width: "75%" }}>
                <Input
                  size="medium"
                  onChange={this.handleChangeInvoiceNumber}
                />
              </div>
            </div>
          </Col>
          <Col xs={11} offset={1}>
            <div style={{ width: "100%", display: "flex" }}>
              <div className="xero-filters-label" style={{ width: "24%" }}>
                <p className={"font-school-list-filter-text-sub"}>
                  Client Tags
                </p>
              </div>
              <div style={{ width: "75%" }}>
                <Select
                  className={"width-web-kit"}
                  placeholder={"Select"}
                  onChange={this.handleChangeClientTags}
                  value={
                    this.state.clientTagId === 0
                      ? undefined
                      : this.state.clientTagId
                  }
                >
                  <Option value={0}>{"Select"}</Option>
                  {this.props &&
                    this.props.clientTagRes &&
                    this.props.clientTagRes.items &&
                    this.props.clientTagRes.items.map((data) => {
                      // eslint-disable-next-line react/jsx-no-undef
                      return <Option value={data.id}>{data.name}</Option>;
                    })}
                </Select>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "19px", marginTop: "19px" }}>
          <Col xs={11} offset={1}>
            <div style={{ width: "100%", display: "flex" }}>
              <div className="xero-filters-label" style={{ width: "24%" }}>
                <p className={"font-school-list-filter-text-sub"}>
                  Processing Person
                </p>
              </div>
              <div style={{ width: "75%" }}>
                <Select
                  // mode="multiple"
                  virtual={false}
                  placeholder="Select processing person"
                  className={"width-selects-filters"}
                  size="middle"
                  onChange={this.handleChangeProcessingPerson}
                >
                  <Option value={""}>{"Select"}</Option>
                  {this.state.teamMembersState}
                </Select>
              </div>
            </div>
          </Col>
          <Col xs={5} offset={3}>
            <div style={{ display: "flex" }}>
              <Button
                className="button-blue"
                size="medium"
                type="primary"
                style={{ marginRight: "4px" }}
                onClick={this.showAll}
              >
                Show All
              </Button>
              <Button
                className="button-blue"
                size="medium"
                type="primary"
                style={{ marginRight: "4px" }}
                onClick={this.show}
              >
                Search
              </Button>
              <Button
                className="button-blue"
                size="medium"
                type="primary"
                onClick={this.exportInvoice}
              >
                Export
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  handleClickViewDetail = (clientName, clientId, clientEmail) => {
    this.setState({ parentInvoiceId: 0 }, () => {
      this.props.getChildInfo(
        this.state.parentInvoiceId,
        this.state.childrenTotalAmount,
        this.state.parentTotalAmount,
        "add",
        clientName,
        clientId,
        clientEmail
      );
      console.log("parentinvoiceid", this.state.idInvoice);
    });
  };

  handleCancelDeleteModal = (e) => {
    this.setState({
      visibleDeleteModal: false,
    });
  };

  getModalDelete = () => {
    return (
      <div>
        <Modal
          title="Delete"
          visible={this.state.visibleDeleteModal}
          onCancel={this.handleCancelDeleteModal}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure, you want to delete invoice?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={5} offset={15}>
              <Button
                onClick={() => {
                  this.handleCancelDeleteModal();
                }}
                style={{ borderRadius: "5px" }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button button-blue"}
                onClick={() => {
                  this.handleDeleteInvoice(this.state.index);
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  handleDeleteInvoice = (id) => {
    this.props.deleteInvoice(id).then(() => {
      message.success("Invoice is deleted successfully!");
      this.setState({ visibleDeleteModal: false });
      setTimeout(() => {
        this.setState({ expandedRowKeys: [], loading: true });
        this.props && this.props.keyd === "6"
          ? this.props
              .getAllTypesInvoices(this.state)
              .then(() => {
                this.setState({ loading: false });
              })
              .catch(() => {
                this.setState({ loading: false });
              })
          : this.props
              .getDueOverDueInvoices(this.state)
              .then(() => {
                this.setState({ loading: false });
              })
              .catch(() => {
                this.setState({ loading: false });
              });
      }, 2000);
    });
  };

  setPClientScreen = (record) => {
    if (record.subjectTypeId === 3) {
      this.props.getPotentialClientInfo(record.subjectId).then(() => {
        this.props.getPotentialClientUpdateBol(false);
        this.props.history.push("/update-potential-client");
      });
    }
  };

  columns = [
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div
          className="invoice-status-btn"
          style={{
            backgroundColor:
              record.statusName === "Saved"
                ? "#f0ad4e"
                : record.statusName === "Sent"
                ? "#5bc0de"
                : record.statusName === "Completed" ||
                  record.statusName === "Completed/Sent"
                ? "#5cb85c"
                : record.statusName === "Posted"
                ? "#9932CC"
                : record.statusName === "Reconciled"
                ? "#2E8B57"
                : record.statusName === "Paid"
                ? "#B87333"
                : record.statusName === "Settled"
                ? "#f64a8a"
                : "#ffff",
          }}
        >
          <span className="invoice-status-text-due-over">
            {record.statusName}
          </span>
        </div>
      ),
    },
    {
      title: "Client ID",
      dataIndex: "ezmId",
      key: "ezmId",
    },
    {
      title: "Client Name",
      dataIndex: "to",
      key: "to",
      render: (text, record) => {
        return (
          <Link
            to={{
              pathname:
                record.subjectTypeId === 2 || record.subjectTypeId === 8
                  ? record.memberType.toLowerCase() === "client"
                    ? "/profile"
                    : record.memberType.toLowerCase() === "spouse"
                    ? "/partner-profile"
                    : "/client-family-member"
                  : record.subjectTypeId === 3
                  ? "/accounts"
                  : record.subjectTypeId === 4
                  ? "/accounts"
                  : "/accounts",
              state: { id: record.subjectId, familyId: record.familyId },
            }}
          >
            <p
              onClick={() => {
                this.setPClientScreen(record);
              }}
              style={{
                fontSize: "12px",
                fontWeight: "400",
                fontStyle: "normal",
                color: "rgba(0, 0, 0, 0.85)",
              }}
            >
              {record.to}
            </p>
          </Link>
        );
      },
    },
    {
      title: "Invoice#",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
    },
    {
      title: "Date",
      dataIndex: "dateStr",
      key: "dateStr",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {moment(record.dateStr).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: "Payment",
      dataIndex: "payment",
      key: "payment",
      render: (text, record) => {
        // let _items =
        //   record.payments && record.payments.items && record.payments.items;
        // let _amount = 0;
        // if (_items && _items.length > 0) {
        //   for (let index = 0; index < _items.length; index++) {
        //     _amount = _amount + _items[index].amount;
        //   }
        // }

        return (
          <p
            style={{
              fontSize: "12px",
              fontWeight: "400",
              fontStyle: "normal",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {record.paidAmount}
          </p>
        );
      },
    },
    {
      title: "Due/Over Due Amount",
      dataIndex: "dueAmount",
      key: "dueAmount",
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <a
          onClick={() => {
            this.setState({ loading: true });
            if (this.state.subjectTypeId === 5) {
              this.props
                .getSchoolInvoiceInfo(record.id)
                .then(() => {
                  this.setState({ loading: false });
                  this.handleClickViewDetail(
                    record.to,
                    record.subjectId,
                    record.email
                  );
                  this.props &&
                    this.props.getInvoiceDepartment(record.subjectTypeId);
                })
                .catch(() => {
                  this.setState({ loading: false });
                });
            } else if (this.state.subjectTypeId === 8) {
              this.props
                .getInvoiceInfo(record.id)
                .then(() => {
                  this.setState({}, () => {
                    this.props
                      .getTemplateInvoiceInfo(record.templateRefId)
                      .then((res) => {
                        this.handleClickViewDetail(
                          record.to,
                          record.subjectId,
                          record.email
                        );
                        this.props &&
                          this.props.getInvoiceDepartment(record.subjectTypeId);
                        this.props && this.props.getTemplateInfoForInvoice(res);
                        this.setState({ loading: false });
                      })
                      .catch(() => {
                        this.setState({ loading: false });
                      });
                  });
                })
                .catch(() => {
                  this.setState({ loading: false });
                });
            } else {
              this.props
                .getInvoiceInfo(record.id)
                .then(() => {
                  this.setState({ loading: false });

                  this.handleClickViewDetail(
                    record.to,
                    record.subjectId,
                    record.email
                  );
                  this.props &&
                    this.props.getInvoiceDepartment(record.subjectTypeId);
                })
                .catch(() => {
                  this.setState({ loading: false });
                });
            }
          }}
        >
          {"View Details"}
        </a>
      ),
    },
    // {
    //   title: "Action",
    //   dataIndex: "id",
    //   key: "id",
    //   render: (text, record) => (
    //     <span>
    //       <a
    //         href="javascript:"
    //         onClick={(e) => {
    //           e.stopPropagation();
    //           this.showModalDelete(record.id);
    //         }}
    //       >
    //         <DeleteOutlined />
    //       </a>
    //     </span>
    //   ),
    // },
  ];

  addInvoiceChild = (clientName, clientId, clientEmail) => {
    this.setState({ parentInvoiceId: this.state.idInvoice }, () => {
      this.props.getChildInfo(
        this.state.parentInvoiceId,
        this.state.childrenTotalAmount,
        this.state.parentTotalAmount,
        "add",
        clientName,
        clientId,
        clientEmail
      );
      console.log("parentinvoiceid", this.state.idInvoice);
    });
  };

  expandedColumns = [
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div
          className="invoice-status-btn"
          style={{
            backgroundColor:
              record.statusName === "Saved"
                ? "#f0ad4e"
                : record.statusName === "Sent"
                ? "#5bc0de"
                : record.statusName === "Completed" ||
                  record.statusName === "Completed/Sent"
                ? "#5cb85c"
                : record.statusName === "Posted"
                ? "#9932CC"
                : record.statusName === "Reconciled"
                ? "#2E8B57"
                : record.statusName === "Paid"
                ? "#B87333"
                : record.statusName === "Settled"
                ? "#f64a8a"
                : "#ffff",
            width: "70px",
          }}
        >
          <span className="invoice-status-text">{record.statusName}</span>
        </div>
      ),
    },
    {
      title: "Client ID",
      dataIndex: "ezmId",
      key: "ezmId",
    },
    {
      title: "Client Name",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "Invoice#",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
    },
    {
      title: "Date",
      dataIndex: "dateStr",
      key: "dateStr",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {moment(record.dateStr).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: "Payment",
      dataIndex: "payment",
      key: "payment",
      render: (text, record) => {
        let _items =
          record.payments && record.payments.items && record.payments.items;
        let _amount = 0;
        if (_items && _items.length > 0) {
          for (let index = 0; index < _items.length; index++) {
            _amount = _amount + _items[index].amount;
          }
        }

        return (
          <p
            style={{
              fontSize: "12px",
              fontWeight: "400",
              fontStyle: "normal",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {_amount}
          </p>
        );
      },
    },
    {
      title: "Due/Over Due Amount",
      dataIndex: "dueAmount",
      key: "dueAmount",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record.dueAmount}
        </p>
      ),
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <a
          onClick={() => {
            this.setState({ loading: true });
            let _childrenTotalAmount = 0;
            _childrenTotalAmount =
              this.state.childrenTotalAmount - record.total;

            this.setState({ childrenTotalAmount: _childrenTotalAmount }, () => {
              this.props
                .getInvoiceInfo(record.id)
                .then(() => {
                  this.setState({ loading: false });
                  this.addInvoiceChild(
                    record.to,
                    record.subjectId,
                    record.email
                  );
                  this.props &&
                    this.props.getInvoiceDepartment(record.subjectTypeId);
                })
                .catch(() => {
                  this.setState({ loading: false });
                });
            });
          }}
        >
          {"View Details"}
        </a>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      // width: "105px",
      render: (text, record) => (
        <span>
          <a
            href="javascript:"
            onClick={(e) => {
              e.stopPropagation();
              this.showModalDelete(record.id);
            }}
          >
            <DeleteOutlined color="rgb(51, 170, 218)" />
          </a>
        </span>
      ),
    },
  ];

  showModalDelete = (id) => {
    this.setState({
      visibleDeleteModal: true,
      index: id,
    });
  };

  onChange = (value) => {
    this.setState({ subjectTypeId: value, defaultCurrent: 1 }, () => {
      this.setState({ loadingTable: true });
      this.props && this.props.keyd === "6"
        ? this.props
            .getAllTypesInvoices(this.state)
            .then(() => {
              this.setState({ loadingTable: false });
            })
            .catch(() => {
              this.setState({ loadingTable: false });
            })
        : this.props
            .getDueOverDueInvoices(this.state)
            .then(() => {
              this.setState({ loadingTable: false });
            })
            .catch(() => {
              this.setState({ loadingTable: false });
            });
    });
  };

  onExpand = (record, expanded) => {
    this.setState({ expandedRowKeys: record.key });
    if (expanded) {
      this.setState({ expandedRowKeys: [] });
    }
    let _childrenTotalAmount = 0;
    this.setState({ idInvoice: record.id, parentTotalAmount: record.total });
    let _data = record && record.links && record.links[1].value;

    this.props.getChildInvoiceListing(_data).then((res) => {
      let _childInvoices =
        res && res.payload && res.payload.items && res.payload.items;
      if (_childInvoices && _childInvoices) {
        for (let index = 0; index < _childInvoices.length; index++) {
          _childrenTotalAmount =
            _childrenTotalAmount + _childInvoices[index].total;
        }
      }

      this.setState({
        childInvoices: _childInvoices,
        childrenTotalAmount: _childrenTotalAmount,
      });
    });
  };

  getTable = () => {
    return (
      <div>
        <Row>
          <Col xs={6} style={{ marginBottom: "37px" }}>
            <Select
              placeholder="Select Invoice Department"
              value={this.state.subjectTypeId}
              onChange={this.onChange}
            >
              <Option value={0}>All Invoices</Option>
              <Option value={8}>Template Invoices</Option>
              <Option value={2}>Client Invoices</Option>
              <Option value={3}>Potential Client Invoices</Option>
              <Option value={4}>Employer Invoices</Option>
              <Option value={5}>School Invoices</Option>
            </Select>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            {this.state.loadingTable ? (
              <div className={"spinner-xero"}>
                <Spin size="large" />
              </div>
            ) : (
              <Row>
                <Col xs={24} className={"school-table overflow"}>
                  <Table
                    expandedRowKeys={this.state.expandedRowKeys}
                    expandable={{
                      expandIconColumnIndex: 9,
                      // expandedRowKeys: [this.state.invoicesList[0].id],
                      expandedRowRender: (record) => (
                        <Row>
                          <Col xs={24}>
                            <Row>
                              <Col
                                xs={24}
                                className="accounts-flow-table school-table"
                              >
                                <Table
                                  dataSource={
                                    this.state.childInvoices
                                      ? this.state.childInvoices
                                      : null
                                  }
                                  rowClassName={(record, index) =>
                                    "invoices-children-table-row"
                                  }
                                  columns={this.expandedColumns}
                                  bordered={false}
                                  pagination={false}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ),

                      expandIcon: ({ expanded, onExpand, record }) => (
                        <div className="table-action" type="right">
                          {record.invoiceTypeId === 2 &&
                          record.subjectTypeId !== 5 &&
                          record.subjectTypeId !== 8 ? (
                            <PlusCircleOutlined
                              style={{ float: "right" }}
                              onClick={(e) => {
                                onExpand(record, e);
                                this.onExpand(record, expanded);
                              }}
                            />
                          ) : null}
                        </div>
                      ),
                    }}
                    dataSource={
                      this.props && this.props.keyd === "6"
                        ? this.state.allTypesInvoices
                          ? this.state.allTypesInvoices
                          : null
                        : this.state.dueOverInvoices
                        ? this.state.dueOverInvoices
                        : null
                    }
                    onChange={this.paginate}
                    columns={this.columns}
                    pagination={{
                      defaultCurrent: this.state.defaultCurrent,
                      total: this.state.totalPages,
                      defaultPageSize: this.state.pageSize,
                      pageSizeOptions: ["20", "50", "100"],
                    }}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const letterTempaltesFilter = (employerManagRes) => {
      let filterList = [];
      if (this.props && this.props.keyd === "6") {
        if (
          this.state &&
          this.state.allTypesInvoices &&
          this.state.allTypesInvoices.length > 0
        ) {
          this.state &&
            this.state.allTypesInvoices &&
            this.state.allTypesInvoices.map((data, index) => {
              if (data) {
                data.index = index;
                data.key = `${index + 1}`;
                filterList.push(data);
              }
            });
        }
      } else {
        if (
          this.state &&
          this.state.dueOverInvoices &&
          this.state.dueOverInvoices.length > 0
        ) {
          this.state &&
            this.state.dueOverInvoices &&
            this.state.dueOverInvoices.map((data, index) => {
              if (data) {
                data.index = index;
                data.key = `${index + 1}`;
                filterList.push(data);
              }
            });
        }
      }
    };
    return (
      <div>
        {this.state.loading ? (
          <div className={"spinner"}>
            <Spin size="large" />
          </div>
        ) : (
          <Fragment>
            {this.props && this.props.keyd === "6" ? null : (
              <Row>
                <Col xs={24}>
                  <h4 style={{ marginLeft: "28px", marginTop: "12px" }}>
                    {"Outstanding Invoices"}
                  </h4>
                </Col>
              </Row>
            )}
            <div className="ts-container-import-clients">
              {this.getFiltersSection()}
            </div>
            <div className="ts-container-import-clients">{this.getTable()}</div>
            {this.getModalDelete()}
            {letterTempaltesFilter()}
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,
    dueOverInvoices:
      state.accountReducer &&
      state.accountReducer.dueOverInvoices &&
      state.accountReducer.dueOverInvoices.items &&
      state.accountReducer.dueOverInvoices.items,
    dueOverInvoicesCount:
      state.accountReducer &&
      state.accountReducer.dueOverInvoicesCount &&
      state.accountReducer.dueOverInvoicesCount,
    clientTagRes:
      state.accountSetReducer &&
      state.accountSetReducer.clientTagRes &&
      state.accountSetReducer.clientTagRes,
    allTypesInvoices:
      state.accountReducer &&
      state.accountReducer.allTypesInvoices &&
      state.accountReducer.allTypesInvoices.items &&
      state.accountReducer.allTypesInvoices.items,
    allTypesInvoicesCount:
      state.accountReducer &&
      state.accountReducer.allTypesInvoicesCount &&
      state.accountReducer.allTypesInvoicesCount,
    teamMembers:
      state &&
      state.teamMemberReducer &&
      state.teamMemberReducer.teamMembers &&
      state.teamMemberReducer.teamMembers.users &&
      state.teamMemberReducer.teamMembers.users,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getInvoiceInfo: bindActionCreators(getInvoiceInfo, dispatch),
  getClientTag: bindActionCreators(getClientTag, dispatch),
  getDueOverDueInvoices: bindActionCreators(getDueOverDueInvoices, dispatch),
  getAllTypesInvoices: bindActionCreators(getAllTypesInvoices, dispatch),
  getChildInvoiceListing: bindActionCreators(getChildInvoiceListing, dispatch),
  deleteInvoice: bindActionCreators(deleteInvoice, dispatch),
  getSchoolInvoiceInfo: bindActionCreators(getSchoolInvoiceInfo, dispatch),
  getTemplateInvoiceInfo: bindActionCreators(getTemplateInvoiceInfo, dispatch),
  getPotentialClientUpdateBol: bindActionCreators(
    getPotentialClientUpdateBol,
    dispatch
  ),
  getPotentialClientInfo: bindActionCreators(getPotentialClientInfo, dispatch),
  getTeamMember: bindActionCreators(getTeamMember, dispatch),
});
DueInvoiceListing = connect(
  mapStateToProps,
  mapDispatchToProps
)(DueInvoiceListing);

export default withRouter(DueInvoiceListing);
