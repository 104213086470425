import React, { Fragment, useState, useEffect } from "react";
import { Select, message, DatePicker, Spin } from "antd";
import moment from "moment";

import UpdateStatusCard from "../../Components/ClientVisa/UpdateStatusCard";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const { Option } = Select;

const dateFormat = "DD/MM/YYYY";

const UpdataVisaStatus = ({
  visaStatusData,
  visaAppData,
  onUpdateCaseStatus,
  onGetVisaApplication,

  onSetActiveKey,
  onUpdatePriority,
  visaPriorityRes,

  onSignedVisa,
  onPaidVisa,
  activeKey,
  onGetVisaStatus,
  clientId,
  isEmployer,
  onGetEmployerCase,
  employerCaseRes,
  onGetEmployerData,
  profileData,
  profileDataRdx,
  onGetVisaNotification,
  setEmailTemplate,
  sendDynamicLinkEmail,
}) => {
  const [caseStatusId, setCaseStatusId] = useState("0");
  const [caseStatusDate, setCaseStatusDate] = useState("");
  const [caseExpiryDate, setCaseExpiryDate] = useState("");
  const [caseApprovedDate, setCaseApprovedDate] = useState("");
  const [visaStatusNotification, setVisaStatusNotification] = useState(0);
  const [loadVisa, setLoadVisa] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const [caseData, setCaseData] = useState(null);
  console.log("show visa  status data ", visaStatusData);

  useEffect(() => {
    if (activeKey === "1") {
      setLoadVisa(true);
      onGetVisaStatus();
      if (isEmployer) {
        onGetEmployerCase(clientId)
          .then((res) => {
            setCaseData(employerCaseRes);
            setLoadVisa(false);
          })
          .catch((err) => {
            setCaseData(visaAppData);
            setLoadVisa(false);
          });
      } else {
        debugger;
        if(!visaAppData || (visaAppData && visaAppData.items && visaAppData.items.length > 0 && visaAppData.items[0].subjectId !== clientId)) {
          setCaseData([]);
        onGetVisaApplication(clientId)
          .then((res) => {
            setLoadVisa(false);
          })
          .catch((err) => {
            setLoadVisa(false);
          });
        } else{
          setLoadVisa(false);
        }
        onGetVisaNotification().then((res) => {
          setVisaStatusNotification(res.payload.settings);
        });
      }
    }
  }, [activeKey]);

  useEffect(() => {
    if (activeKey == "1") {
      if (isEmployer && employerCaseRes) {
        setCaseData(employerCaseRes.items);
      } else if (visaAppData) {
        setCaseData(visaAppData.items);
      }
    }
  }, [employerCaseRes, visaAppData, caseData]);

  if (visaStatusData && visaStatusData.items) {
    var visaStatuses = [];
    for (var i = 0; i < visaStatusData.items.length; i++) {
      visaStatuses.push(
        <Option key={visaStatusData.items[i].id}>
          {visaStatusData.items[i].name}
        </Option>
      );
    }
  }

  const onChange = (value) => {
    console.log(`selected ${value}`);

    setCaseStatusId(value);
  };

  const onChangeDate = (value, dateString) => {
    setCaseStatusDate(value);
  };

  const onChangeExpiryDate = (value, dateString) => {
    setCaseExpiryDate(value);
  };

  const onChangeApprovedDate = (value, dateString) => {
    setCaseApprovedDate(value);
  };

  const updateStatus = (visa) => {
    let data = {
      id: visa.id,
      caseStatusId: parseInt(caseStatusId),
      approveDate: caseApprovedDate || new Date(),
      expiryDate: caseExpiryDate || new Date(),
      date: caseStatusDate || new Date(),
      branchVisaTypeId: visa.branchVisaTypeId,
    };
    onUpdateCaseStatus(data).then((res) => {
      message.success("Visa status updated successfully");
      onGetVisaApplication();
    });
  };

  return (
    <Fragment>
      <Spin spinning={loadVisa}>
        {caseData &&
          caseData.map((visa, index) => {
            return (
              <div key={index}>
                <UpdateStatusCard
                  visa={visa}
                  onUpdateCaseStatus={onUpdateCaseStatus}
                  onGetVisaApplication={onGetVisaApplication}
                  visaStatusData={visaStatusData}
                  onSetActiveKey={onSetActiveKey}
                  onUpdatePriority={onUpdatePriority}
                  visaPriorityRes={visaPriorityRes}
                  onSignedVisa={onSignedVisa}
                  onPaidVisa={onPaidVisa}
                  clientId={clientId}
                  isEmployer={isEmployer}
                  onGetEmployerCase={onGetEmployerCase}
                  onGetEmployerData={onGetEmployerData}
                  profileDataEmp={profileData}
                  profileDataRdx={profileDataRdx}
                  visaStatusNotification={visaStatusNotification}
                  setEmailTemplate={setEmailTemplate}
                  sendDynamicLinkEmail={sendDynamicLinkEmail}
                />
              </div>
            );
          })}
      </Spin>
    </Fragment>
  );
};

export default UpdataVisaStatus;
